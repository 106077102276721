require("typeface-kanit")
require("typeface-montserrat")

const theme = {
  colors: {
    bodyBackground: "#F5F5F5",
    pageEnd: "#dddddd",
    darkFont: "#342A2A",
    modalBackground: "rgba(52, 42, 42, 0.8)",
    fontBright: "#ffffff",
    white: "#ffffff",
    buttonRed: "#FE4345",
    buttonRedHover: "#ff5454",
    whiteMute: "#eaecf0",
    error: "#fa5252",
    focusedElement: "#a5a4a4",
    counterColorRed: "#a31e31"
  },
  fontsizes: {
    bannerMediumScreen: "6vw",
    subBannerMediumScreen: "2vw",
    textMediumScreen: "1.5vw",
    smallTextMediumScreen: "1vw",
    h1MediumScreen: "3vw",
    h2MediumScreen: "2.5vw",
    h3MediumScreen: "2vw",
    textSmallScreen: "3vw",
    smallTextSmallScreen: "2vw",
    h1SmallScreen: "5vw",
    h2SmallScreen: "4.5vw",
    h3SmallScreen: "4vw",
    bannerSmallScreen: "7vw",
    subBannerSmallScreen: "4vw"
  },
  spaces: {
    standardPadding: "6vw",
    standardBlockTopEnd: "9vw",
    textMediumScreenLineHeight: "2.5vw",
    textSmallScreenLineHeight: "5vw",
    smallSpacing: "2.5vw",
    buttonPaddingTopBigScreen: "0.5vw",
    buttonPaddingSideBigScreen: "1vw",
    buttonPaddingTopSmallScreen: "1vw",
    buttonPaddingSideSmallScreen: "2vw",
    dropdownOptionPaddingBigScreen: "1vw"
  },
  buttonSizes: {
    projectButton: "2.5vw",
    projectButtonSmallScreen: "3.5vw"
  },
  lineStrength: {
    seperators: ".15vw",
    buttons: ".1vw"
  },
  fontFamilyHeading: '"Kanit", sans-serif',
  fontFamily: '"Montserrat", sans-serif',
  br: "10px"
}

export default theme
